import HeroIllustration from '../../assets/hackathon_hero.png';
import HeroIllustrationMobile from '../../assets/hackathon_hero_mobile.png';
import {
	ArrowTopRightOnSquareIcon,
	ArrowDownIcon,
} from '@heroicons/react/24/outline';
import SocialScienceLogo from '../../assets/social_science_logo.svg';
import McMasterLogo from '../../assets/mcmaster_logo.svg';
import AccordionLayout from '../../components/Accordion/Accordion';
import { hackathonFaq, hackathonFaqContinue } from '../../utils';

function Hackathon() {
	const hackathonFormUrl =
		'https://forms.office.com/Pages/DesignPageV2.aspx?subpage=design&FormId=B2M3RCm0rUKMJSjNSW9HcrQY3BEUwlNEjbudoLiNBa9UMUlCUEVXQjk0REtOQzhMT0ZBRTRUUThNQi4u&Token=17e8bba5132c4bb8bfdf9c6a8088e042';
	return (
		<div className="bg-soft-black">
			<div className="flex justify-center items-center py-[80px] px-[10px] md:py-[172px] lg:px-0">
				<img
					src={HeroIllustration}
					alt="Digital Society Hackathon. The dates are from January 25th to January 31st. The format is hybrid: onle and at the McMaster campus"
					className="max-w-[800px] w-full hidden sm:block"
				/>
        <img
        src={HeroIllustrationMobile}
        alt="Digital Society Hackathon. The dates are from January 25th to January 31st. The format is hybrid: onle and at the McMaster campus"
        className="max-w-[800px] w-full sm:hidden"
      />
			</div>

			<div className="text-neutral-100 flex justify-center py-[80px] md:py-[172px]">
				<a href="#apply" className="flex flex-col items-center justify-center">
					<h2 className="text-[13px] bold uppercase">Apply</h2>

					<ArrowDownIcon className="w-[24px] h-[24px]" />
				</a>
			</div>

			<div className="wrapper text-neutral-100">
				<div className="max-w-[920px] mb-[100px] sm:mb-[140px] m-auto flex flex-wrap sm:flex-nowrap justify-between items-start">
					<h1 className="text-[30px] sm:text-[42px] uppercase mb-[24px] sm:mb-0 sm:mr-[72px]">
						A SOCIAL IMPACT <br /> HACKATHON
					</h1>
					<p className="sm:w-[430px] leading-[28px]">
						The Digital Society Lab presents a hackathon event that encourages
						participants to creatively think about the various ways we can
						create meaningful change within society. We encourage those who are
						interested in{' '}
						<span className="italic">
							social innovation, entrepreneurship, engineering, design,
						</span>
						and <span className="italic">technology</span> to apply!
					</p>
				</div>
				<div className="max-w-[920px] mb-[100px] sm:mb-[140px] m-auto flex flex-wrap sm:flex-nowrap justify-between items-start">
					<h1 className="text-[30px] sm:text-[42px] uppercase mb-[24px] sm:mb-0 sm:mr-[72px]">
						HOW IT WORKS
					</h1>
					<p className="sm:w-[430px] leading-[28px]">
						Attendees can join the event either as an individual or as part of a
						team of up to 4 members. During the opening ceremony, a prompt will
						be revealed of which all teams will follow when creating their
						project. Prior to the deadline, users will submit a video
						presentation for judging. Finalists will be called upon to do a live
						presentation for everyone, showcasing their hard work. Winners will
						then be announced at our closing ceremony!
					</p>
				</div>
				<div className="max-w-[920px] m-auto mb-[40px]">
					<h1 className="text-[30px] sm:text-[42px] uppercase mb-[24px] sm:mb-[40px]">
						PRIZES + AWARDS
					</h1>
					<div className="grid grid-cols-1 gap-4 md:grid-cols-4 lg:gap-8">
						<div className="border-b md:border-r md:border-b-0 border-neutral-100 p-[20px] pl-0">
							<p className="text-[18px]">1st Place</p>
							<h2 className="text-4xl font-bold my-[12px]">$1000</h2>
							<p className="text-[16px] font-semibold">
								{' '}
								invitation for development*
							</p>
						</div>
						<div className="border-b md:border-r md:border-b-0 border-neutral-100 p-[20px] pl-0">
							<p className="text-[18px]">2nd Place</p>
							<h2 className="text-4xl font-bold my-[12px]">$500</h2>
						</div>
						<div className="border-b md:border-r md:border-b-0 border-neutral-100 p-[20px] pl-0">
							<p className="text-[18px]">3rd Place</p>
							<h2 className="text-4xl font-bold my-[12px]">$250</h2>
						</div>

						<div className="flex items-center">
							<h2 className="text-[24px] font-bold">+ more</h2>
						</div>
					</div>
				</div>
				<div className="p-[40px] border-neutral-100 rounded-[10px] border border-2 max-w-[920px] m-auto mb-[100px] sm:mb-[140px]">
					<h1 className="text-[30px] sm:text-[42px] uppercase mb-[28px]">
						TURN YOUR IDEAS INTO <br /> REALITY
					</h1>
					<p className="text-[18px] md:text-[18px] leading-[28px]">
						*We will be giving the 1st place winner an invitation to turn their
						ideas into an actual product while collaborating with our team at
						the lab. This is your opportunity to be a part of a product
						development cycle.
					</p>
				</div>
				<div className="max-w-[920px] mb-[100px] sm:mb-[140px] m-auto grid grid-cols-1 gap-4 md:grid-cols-2 lg:gap-8">
					<div>
						<h1 className="text-[30px] sm:text-[42px] uppercase mb-[28px]">
							LEARN & BE LIMITLESS
						</h1>
						<p className="text-[18px] md:text-[18px] leading-[28px]">
							Put your skills to the test while learning new things or brushing
							up on your knowledge. Workshops and mentorship will be available
							to all participants.
						</p>
					</div>
					<div>
						<div className="flex items-center gap-4 mb-[40px]">
							<div className="text-center">
								<h2 className="text-[13px] font-bold leading-tight">SAT</h2>
								<h2 className="text-[24px] font-bold">25</h2>
							</div>
							<div className="w-full flex flex-col gap-[8px]">
								<div className="rounded-[5px] bg-[#E5F2E1] text-[#1A490A] py-[8px] px-[12px]">
									<p className="text-[14px]">
										Workshop: Intro to Design Thinking
									</p>
								</div>
								<div className="rounded-[5px] bg-[#E5F2E1] text-[#1A490A] py-[8px] px-[12px]">
									<p className="text-[14px]">
										Workshop: User research, journey, and empathy
									</p>
								</div>
							</div>
						</div>
						<div className="flex items-center gap-4">
							<div className="text-center">
								<h2 className="text-[13px] font-bold leading-tight">SUN</h2>
								<h2 className="text-[24px] font-bold">26</h2>
							</div>
							<div className="w-full flex flex-col gap-[8px]">
								<div className="rounded-[10px] bg-[#E5F2E1] text-[#1A490A] py-[8px] px-[12px]">
									<p className="text-[14px]">
										Workshop: Product Development - Discovery, Ideation,
										Prototype
									</p>
								</div>
								<div className="rounded-[10px] bg-[#E5F2E1] text-[#1A490A] py-[8px] px-[12px]">
									<p className="text-[14px]">Workshop: Wireframing 101</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="max-w-[920px] mb-[100px] sm:mb-[140px] m-auto">
					<h1 className="text-[30px] text-center sm:text-[42px] uppercase mb-[40px]">
						January ITINERARY
					</h1>
					<div className="grid grid-cols-1 md:grid-cols-7 gap-1">
						<div className="flex gap-4 md:gap-0 md:grid grid-cols-2 items-center md:items-start md:gap-4 md:grid-cols-1 border-b md:border-r md:border-b-0 border-[#545454] pr-[8px] py-[12px] pl-0">
							<div className="md:text-center mb-[16px]">
								<h2 className="text-[13px] font-bold leading-tight">SAT</h2>
								<h2 className="text-[24px] font-bold">25</h2>
							</div>
							<div className="w-full">
								<div className="mb-[8px] rounded-[5px] border border-soft-black bg-white text-soft-black p-[8px]">
									<p className="text-[14px]">Check-ins</p>
								</div>
								<div className="mb-[8px] rounded-[5px] border border-soft-black bg-[#F7ECDD] text-[#694213] p-[8px]">
									<p className="text-[14px]">Opening Ceremony</p>
								</div>
								<div className="mb-[8px] rounded-[5px] border border-soft-black bg-[#F9EAF7] text-[#5F0852] p-[8px]">
									<p className="text-[14px]">Hacking begins</p>
								</div>
								<div className="mb-[8px] rounded-[5px] border border-soft-black bg-[#E5F2E1] text-[#1A490A] p-[8px]">
									<p className="text-[14px]">Workshops</p>
								</div>
							</div>
						</div>

						<div className="flex gap-4 md:gap-0 md:grid grid-cols-2 items-center md:items-start md:gap-4 md:grid-cols-1 border-b md:border-r md:border-b-0 border-[#545454] pr-[8px] py-[12px] pl-0">
							<div className="md:text-center mb-[16px]">
								<h2 className="text-[13px] font-bold leading-tight">SUN</h2>
								<h2 className="text-[24px] font-bold">26</h2>
							</div>
							<div className="w-full">
								<div className="mb-[8px] rounded-[5px] border border-soft-black bg-[#E5F2E1] text-[#1A490A] p-[8px]">
									<p className="text-[14px]">Workshops</p>
								</div>
								<div className="mb-[8px] rounded-[5px] border border-soft-black bg-[#E9EEF2] text-[#394A93] p-[8px]">
									<p className="text-[14px]">Mentorship opens</p>
								</div>
							</div>
						</div>

						<div className="flex gap-4 md:gap-0 md:grid grid-cols-2 items-center md:items-start md:gap-4 md:grid-cols-1 border-b md:border-r md:border-b-0 border-[#545454] pr-[8px] py-[12px] pl-0">
							<div className="md:text-center mb-[16px]">
								<h2 className="text-[13px] font-bold leading-tight">MON</h2>
								<h2 className="text-[24px] font-bold">27</h2>
							</div>
							<div className="w-full">
								<div className="mb-[8px] rounded-[5px] border border-soft-black bg-[#E9EEF2] text-[#394A93] p-[8px]">
									<p className="text-[14px]">Mentorship</p>
								</div>
							</div>
						</div>

						<div className="flex gap-4 md:gap-0 md:grid grid-cols-2 items-center md:items-start md:gap-4 md:grid-cols-1 border-b md:border-r md:border-b-0 border-[#545454] pr-[8px] py-[12px] pl-0">
							<div className="md:text-center mb-[16px]">
								<h2 className="text-[13px] font-bold leading-tight">TUE</h2>
								<h2 className="text-[24px] font-bold">28</h2>
							</div>
							<div className="w-full">
								<div className="mb-[8px] rounded-[5px] border border-soft-black bg-[#E9EEF2] text-[#394A93] p-[8px]">
									<p className="text-[14px]">Mentorship</p>
								</div>
							</div>
						</div>

						<div className="flex gap-4 md:gap-0 md:grid grid-cols-2 items-center md:items-start md:gap-4 md:grid-cols-1 border-b md:border-r md:border-b-0 border-[#545454] pr-[8px] py-[12px] pl-0">
							<div className="md:text-center mb-[16px]">
								<h2 className="text-[13px] font-bold leading-tight">WED</h2>
								<h2 className="text-[24px] font-bold">29</h2>
							</div>
							<div className="w-full">
								<div className="mb-[8px] rounded-[5px] border border-soft-black bg-[#E9EEF2] text-[#394A93] p-[8px]">
									<p className="text-[14px]">Mentorship</p>
								</div>
							</div>
						</div>

						<div className="flex gap-4 md:gap-0 md:grid grid-cols-2 items-center md:items-start md:gap-4 md:grid-cols-1 border-b md:border-r md:border-b-0 border-[#545454] pr-[8px] py-[12px] pl-0">
							<div className="md:text-center mb-[16px]">
								<h2 className="text-[13px] font-bold leading-tight">THR</h2>
								<h2 className="text-[24px] font-bold">30</h2>
							</div>
							<div className="w-full">
								<div className="mb-[8px] rounded-[5px] border border-soft-black bg-[#E9EEF2] text-[#394A93] p-[8px]">
									<p className="text-[14px]">Mentorship</p>
								</div>
							</div>
						</div>

						<div className="flex gap-4 md:gap-0 md:grid grid-cols-2 items-center md:items-start md:gap-4 md:grid-cols-1 border-b md:border-r md:border-b-0 border-[#545454] pr-[8px] py-[12px] pl-0">
							<div className="md:text-center mb-[16px]">
								<h2 className="text-[13px] font-bold leading-tight">FRI</h2>
								<h2 className="text-[24px] font-bold">31</h2>
							</div>
							<div className="w-full">
								<div className="mb-[8px] rounded-[5px] border border-soft-black bg-white text-soft-black p-[8px]">
									<p className="text-[14px]">Housekeeping</p>
								</div>
								<div className="mb-[8px] rounded-[5px] border border-soft-black bg-[#F9EAF7] text-[#5F0852] p-[8px]">
									<p className="text-[14px]">Hacking ends</p>
								</div>
								<div className="mb-[8px] rounded-[5px] border border-soft-black bg-[#F0E0E0] text-[#980000] p-[8px]">
									<p className="text-[14px]">Submission + judging</p>
								</div>
								<div className="mb-[8px] rounded-[5px] border border-soft-black bg-[#F7ECDD] text-[#694213] p-[8px]">
									<p className="text-[14px]">Closing Ceremony</p>
								</div>
							</div>
						</div>
					</div>

					<div className="mt-[64px]">
						<p>
							Note: Itinerary is subject to change as we get closer to the
							event.
						</p>
					</div>
				</div>
				<div className="max-w-[920px] mb-[100px] sm:mb-[140px] m-auto flex flex-wrap sm:flex-nowrap justify-between items-start">
					<h1 className="text-[30px] sm:text-[42px] uppercase mb-[24px] sm:mb-0 sm:mr-[72px]">
						NO EXPERIENCE NECESSARY
					</h1>

					<p className="sm:w-[430px] leading-[28px]">
						This hackathon is open to all McMaster students and faculty
						regardless of experience or background. This is a code optional
						event designed to be beginner-friendly. Judging will not be focused
						on technical skills, but rather on your project’s
						<span className="italic">creativity, impact, scalability,</span>
						and <span className="italic">presentation</span>.
					</p>
				</div>

				<div
					id="apply"
					className="max-w-[920px] mb-[100px] sm:mb-[140px] m-auto flex flex-wrap sm:flex-nowrap justify-between items-start">
					<h1 className="text-[30px] sm:text-[42px] uppercase mb-[24px] sm:mb-0 sm:mr-[72px]">
						APPLY HERE
					</h1>

					<a
						href={hackathonFormUrl}
						className="bg-neutral-100 rounded-[5px] w-full max-w-[380px] text-soft-black inline-block inline-flex items-center justify-center uppercase p-[12.5px]"
						target="_blank">
						<h2 className="text-[14px] font-normal">Application form</h2>
						<ArrowTopRightOnSquareIcon className="inline-block w-[18px] h-[18px] ml-1" />
					</a>
				</div>

				<div className="max-w-[920px] pb-[100px] sm:pb-[172px] m-auto">
					<h1 className="text-[30px] sm:text-[42px] uppercase mb-[40px]">
						MADE POSSIBLE WITH HELP FROM
					</h1>

					<div className="flex flex-wrap gap-4 justify-start items-center">
						<img
							src={SocialScienceLogo}
							alt="Social Science and Humanities Research Council"
							className="h-[100px]"
						/>
						<img
							src={McMasterLogo}
							alt="McMaster University"
							className="h-[100px]"
						/>
					</div>
				</div>

				<div className="max-w-[920px] pb-[140px] sm:pb-[140px] m-auto">
					<h1 className="text-[30px] sm:text-[42px] uppercase mb-[40px]">
						FAQs
					</h1>

					<div className="grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-[60px]">
						<div>
							{hackathonFaq?.map((faq, id) => (
								<AccordionLayout key={id} faq={faq} />
							))}
						</div>
						<div>
							{hackathonFaqContinue?.map((faq, id) => (
								<AccordionLayout key={id} faq={faq} />
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Hackathon;
